/* eslint-disable */
<template>
  <v-card fill-height height="100%">

  <v-data-table
  v-model="selected"
  :headers="headers"
  :items="items"
  :search="search"
  :loading="loading"
  :footer-props="{'items-per-page-options':[15,50,100,250,500, -1]}"
  loading-text="Loading... Stand By"
  item-key="id"
  group-by="Message_Type"
  style="height:100%"
  show-select

  class="elevation-1"
>
<template v-slot:top>
  <v-toolbar
    flat
  >
  <v-text-field
    v-model="search"
    append-icon="mdi-magnify"
    label="Search"
    single-line
    hide-details
  ></v-text-field>
    <v-spacer></v-spacer>

    <v-tooltip bottom v-if="selected.length > 0">
      <template v-slot:activator="{ ons, attrs }">
        <v-btn icon color="red" @click="extractedString">
        <v-icon
          color="primary"
          dark
          v-bind="attrs"
          v-on="ons"
        >
          mdi-cursor-text
        </v-icon>
        </v-btn>
      </template>
      <span>Extract pipe seperated string</span>
    </v-tooltip>
    <v-tooltip bottom v-else>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon disabled>
        <v-icon
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          mdi-cursor-text
        </v-icon>
        </v-btn>
      </template>
      <span>Extract pipe seperated string</span>
    </v-tooltip>

    <v-tooltip bottom v-if="selected.length > 0">
      <template v-slot:activator="{ ons, attrs }">
        <v-btn icon color="red" @click="extractedJSON">
        <v-icon
          color="primary"
          dark
          v-bind="attrs"
          v-on="ons"
        >
          mdi-script-text-outline
        </v-icon>
        </v-btn>
      </template>
      <span>Extract raw JSON</span>
    </v-tooltip>
    <v-tooltip bottom v-else>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon disabled>
        <v-icon
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          mdi-script-text-outline
        </v-icon>
        </v-btn>
      </template>
      <span>Extract raw JSON</span>
    </v-tooltip>



    <v-tooltip bottom v-if="selected.length > 1">
      <template v-slot:activator="{ ons, attrs }">
        <v-btn icon color="red" @click="editItems">
        <v-icon
          color="primary"
          dark
          v-bind="attrs"
          v-on="ons"
        >
          mdi-email-sync-outline
        </v-icon>
        </v-btn>
      </template>
      <span>Resend selected items</span>
    </v-tooltip>
    <v-tooltip bottom v-else>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon disabled>
        <v-icon
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          mdi-email-sync-outline
        </v-icon>
        </v-btn>
      </template>
      <span>Resend selected items</span>
    </v-tooltip>

    <v-tooltip bottom v-if="selected.length > 0">
      <template v-slot:activator="{ ons, attrs }">
        <v-btn icon color="red" @click="dialogCancel = true">
        <v-icon
          color="red"
          dark
          v-bind="attrs"
          v-on="ons"
        >
          mdi-cash-remove
        </v-icon>
        </v-btn>
      </template>
      <span>Cancel selected orders</span>
    </v-tooltip>
    <v-tooltip bottom v-else>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon disabled>
        <v-icon
          color="red"
          dark
          v-bind="attrs"
          v-on="on"
        >
          mdi-cash-remove
        </v-icon>
        </v-btn>
      </template>
      <span>Cancel selected orders</span>
    </v-tooltip>

    <v-tooltip bottom v-if="selected.length > 1">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon color="red" @click="dialogDelete = true">
        <v-icon
          color="red"
          dark
          v-bind="attrs"
          v-on="on"
        >
          mdi-trash-can-outline
        </v-icon>
        </v-btn>
      </template>
      <span>Delete selected items</span>
    </v-tooltip>
    <v-tooltip bottom v-else>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon disabled>
        <v-icon
          color="red"
          dark
          v-bind="attrs"
          v-on="on"
        >
          mdi-trash-can-outline
        </v-icon>
        </v-btn>
      </template>
      <span>Delete selected items</span>
    </v-tooltip>

    <v-dialog
      v-model="dialog"
      max-width="1000px"
    >
    <v-form
      lazy-validation
      ref="form"
    >
      <v-card>
        <v-card-title>
          <span v-if="selected.length > 1" class="headline">Editing {{ selected.length }} items</span>
          <span v-else class="headline">Editing {{ selected.length }}</span>
        </v-card-title>

        <v-card-text>
          <v-container v-for="(select,index) in selected" :key="index">
            <v-row>
              <JsonEditor
                  style="width:100%;margin-bottom:20px;padding-right:2%;"
                  :objData="select"
                  v-model="selected[index]" >
              </JsonEditor>
            </v-row>
            <v-divider></v-divider>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="close()"
          >
            Cancel
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="save()"
          >
            Resend
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    </v-dialog>
    <v-dialog
     v-model="dialogdelload"
     hide-overlay
     persistent
     width="300"
   >
     <v-card
       color="black"
       dark
     >
       <v-card-text>
         Please stand by
         <v-progress-linear
           :buffer-value="deleteprogress"
           color="white"
           stream
           class="mb-0 mt-2"
         ></v-progress-linear>
       </v-card-text>
     </v-card>
   </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline">Are you sure you want to delete {{ selected.length }} items?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
     v-model="dialogdelcancel"
     hide-overlay
     persistent
     width="300"
   >
     <v-card
       color="black"
       dark
     >
       <v-card-text>
         Please stand by
         <v-progress-linear
           :buffer-value="cancelprogress"
           color="white"
           stream
           class="mb-0 mt-2"
         ></v-progress-linear>
       </v-card-text>
     </v-card>
   </v-dialog>
    <v-dialog v-model="dialogCancel" max-width="500px">
      <v-card>
        <v-card-title class="headline">Are you sure you want to cancel {{ selected.length }} items?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeCancel">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="cancelItemConfirm">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-model="dialogjson" max-width="700px" persistent>
      <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      top
      centered
      color="green accent-4"
      elevation="24"
    >
     copied to clipboard
    </v-snackbar>
      <v-card>
        <v-card-title class="headline"><v-spacer></v-spacer><v-icon
          medium
          @click="copy_to_clipboard"
        >
          mdi-content-copy
        </v-icon></v-card-title>
        <v-card-text>
          <v-textarea
            v-model="exJSON"
            id="clip"
            label="Content"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer><v-btn color="red darken-1" text @click="closeJSON">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-toolbar>
</template>
<template v-slot:item.actions="{ item }">
  <v-icon
    small
    class="mr-2"
    @click="editItem(item)"
  >
    mdi-email-sync-outline
  </v-icon>
  <v-icon
    small
    @click="deleteItem(item)"
  >
    mdi-delete
  </v-icon>
</template>
<template v-slot:no-data>
</template>
</v-data-table>
</v-card>
</template>

<script>
const CosmosClient = require("@azure/cosmos").CosmosClient;
const config = require("../configqa");

const { endpoint, key, databaseId, containerId } = config;

const client = new CosmosClient({ endpoint, key });

const database = client.database(databaseId);
const container = database.container(containerId);


export default {
  name: "Qa",
  data () {
      return {
        singleSelect: false,
        selected: [],
        loading: true,
        dialog: false,
        exJSON: "",
        snackbar: false,
        timeout: 3000,
        dialogjson: false,
        dialogDelete: false,
        dialogCancel: false,
        dialogdelload: false,
        dialogdelcancel: false,
        deleteprogress: 0,
        cancelprogress: 0,
        valid: false,
        items: [],
        Location_Codes: [
          'CWSE',
          'JJUK',
          'JJUS',
          'CWJP',
          'CWNL',
          'PPAUS',
          'DREXEL',
        ],
        editingitems: [
          "adress",
          "adress2",
          "city",
          "country",
          "name",
          "name2",
          "order_type",
          "Location_Code",
          "zip",
        ],
        search: "",
        headers: [
          {
            text: 'ID',
            align: 'start',
            sortable: false,
            value: 'Order_No',
          },
          { text: 'Entry No', value: 'Entry_No' },
          { text: 'Date', value: 'Order_Date' },
          { text: 'Location', value: 'Location_Code' },
          { text: 'Message Type', value: 'Message_Type' },
          { text: 'Fail reason', value: 'error' },
          { text: 'Error Message', value: 'error_msg_trunc' },
          { text: 'Actions', value: 'actions', sortable: false }
        ],
      }
    },
  async created() {
    const querySpec = {
      query: "SELECT * from errorQa"
    };
    const { resources: container1 } = await container.items
      .query(querySpec)
      .fetchAll();

    container1.forEach(item => {
      if (item.Message_Type == "SHIPMENT-IB" || item.Message_Type == "SHIPMENT-OB" || item.Message_Type == "RECEIPT-IB" || item.Message_Type == "INVENTORY-IB") {
        if (typeof item.JsonText != 'undefined') {
          if (typeof item.JsonText.ShippmentFeedback != 'undefined') {
            if (typeof item.JsonText.ShippmentFeedback.Shipment_Date != 'undefined') {
              item.Order_Date = item.JsonText.ShippmentFeedback.Shipment_Date
            }
          }
        }
      }
      if (item.Message_Type == "ITEM-OB") {
        item.Order_No = item.Item_No;
        if (item.JsonText.UOMS != null) {
          if (item.JsonText.UOMS.length > 0) {
            item.JsonText.UOMS.forEach(prod => {
              if (prod.Is_Default == 'true') {
                prod.Is_Default = true;
              } else {
                prod.Is_Default = false;
              }
            });
          }
        }
      }
      let trunc;
      if (typeof item.error_msg != 'undefined' && item.error_msg != null) {
        if (item.error_msg.length < 100) {trunc = item.error_msg.length}else {trunc = 100}
        item.error_msg_trunc = item.error_msg.slice(0,trunc)
      } else {
        item.error_msg_trunc = ""
      }
      delete item._rid;
      delete item._self;
      delete item._etag;
      delete item._attachments;
      delete item._ts;
      this.items.push(item);
    });
    this.loading = false;
    },
    watch: {
      dialog (val) {
        val || this.close()
      },
      jsondialog (val) {
        val || this.closeJSON()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
      dialogCancel (val) {
        val || this.closeCancel()
      }
    },
    methods: {
      editItem (item) {
        this.selected = [];
        this.selected.push(item)
        this.dialog = true
      },
      editItems () {
        this.bulkediting = true
        this.dialog = true
      },
      async extractedJSON () {
        const pre = Array.from(this.selected)
        this.dialogjson = true;
        this.exJSON = JSON.stringify(pre);
      },
      async extractedString () {
        const pre = Array.from(this.selected)
        this.dialogjson = true;
        this.exJSON = pre.map(e => e.Order_No).join("|");
      },
      deleteItem (item) {
        this.selected = [];
        this.selected.push(item);
        this.dialogDelete = true;
      },
      deleteItems () {
        this.dialogDelete = true;
      },
      copy_to_clipboard () {
        let codeToCopy = document.querySelector('#clip')
        codeToCopy.select()
        try {
          document.execCommand('copy');
        } catch (err) {
          alert('Oops, unable to copy');
        }
        window.getSelection().removeAllRanges()
        this.snackbar = true
      },
      async deleteItemConfirm () {
        this.closeDelete()
        this.dialogdelload = true
        for (var i = 0; i < this.selected.length; i++) {
          try {
  await container.item(this.selected[i].id, this.selected[i].id).delete();
} catch (e) {
  console.log(e);
}
          for (var a = 0; a < this.items.length; a++) {
            if (this.items[a].id == this.selected[i].id) {
              this.items.splice(a, 1);
            }
          }
          this.deleteprogress += (1 / this.selected.length) * 100
        }
        this.dialogdelload = false
        this.selected = [];
        this.deleteprogress = 0
      },
      
      async cancelItemConfirm () {
        this.closeCancel();
        this.dialogdelcancel = true
        for (var i = 0; i < this.selected.length; i++) {

            if (typeof this.selected[i].Order_No == "string") {
              var trycancel = await cancelordergraph(this.selected[i]);
              if (trycancel.success) {
                await container.item(this.selected[i].id, this.selected[i].id).delete();
                for (var a = 0; a < this.items.length; a++) {
                  if (this.items[a].id == this.selected[i].id) {
                    this.items.splice(a, 1);
                  }
                }
                this.cancelprogress += (1 / this.selected.length) * 100
              } else {
                console.log(trycancel.message);
              }
            } else {
                this.cancelprogress += (1 / this.selected.length) * 100
              }
        }
        this.dialogdelcancel = false
        this.selected = [];
        this.cancelprogress = 0
      },
      close () {
        this.dialog = false
      },
      closeDelete () {
        this.dialogDelete = false
      },
      closeCancel () {
        this.dialogCancel = false
      },
      closeJSON () {
        window.location.reload()
      },
      async save () {

        for (var i = 0; i < this.selected.length; i++) {
          try {
  await container.item(this.selected[i].id, this.selected[i].id).delete();
} catch (e) {
  console.log(e);
}
          for (var a = 0; a < this.items.length; a++) {
            if (this.items[a].id == this.selected[i].id) {
              this.items.splice(a, 1);
            }
          }
        }
        await toBus(this.selected)
        this.close()
      }
    }
};
async function toBus (nad) {
  var https = require('follow-redirects').https;

  var options = {
    'method': 'POST',
    'hostname': 'prod-127.westeurope.logic.azure.com',
    'port': 443,
    'path': '/workflows/17ebffc691b14f13966b9d1cfb6b1954/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=ZJ5rgOGnFyvij1mp_F0W_BbBTprcz8ArQoEwBVDNWVU',
    'headers': {
      'Content-Type': 'text/plain'
    },
    'maxRedirects': 20
  };
  var req = https.request(options, function (res) {
    var chunks = [];

    res.on("data", function (chunk) {
      chunks.push(chunk);
    });

    res.on("end", function () {
      var body = Buffer.concat(chunks);
      console.log(body.toString());
      return;
    });

    res.on("error", function (error) {
      console.error(error);
    });
  });

  var predat = JSON.stringify(nad)
  var postData = predat;

  req.write(postData);

  req.end();
}
function cancelordergraph (obj) {
  return new Promise((resolve, reject) => {
  
    var https = require('follow-redirects').https;

    var payload = {"query": "mutation{cancelDirectToConsumerOrder(order:{number:"+ obj.Order_No +"}input:{emailOptions:{sendEmail:false}stockAction:{releaseItemsBackToWarehouse:true}comment:\"Cancelled manually by IIP error client\" cancelAuthorization:true}){order{number orderDate market{allocationRule{name}}paymentMethod{uri}billingAddress{address1 address2 zipCode email country{code}}}userErrors{message path}userWarnings{message path}}}"};
    var result = {success: true, message: ""};
    var options = {
      'method': 'POST',
      'hostname': 'prod-106.westeurope.logic.azure.com',
      'port': 443,
      'path': '/workflows/a572068ad30b437bb2156d82de88c74d/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=EVtKFcYRATM9tqqqM9LzS4zIaINFGSH47P71ODQAtzg',
      'headers': {
        'Content-Type': 'application/json',
      },
      'maxRedirects': 20
    };
    var req = https.request(options, function (res) {
      var chunks = [];

      res.on("data", function (chunk) {
        chunks.push(chunk);
      });

      res.on("end", function () {
        var body = JSON.parse(Buffer.concat(chunks).toString());
        if (body.data.cancelDirectToConsumerOrder.userErrors.length > 0) {
          result.success = false;
          result.message = body.data.cancelDirectToConsumerOrder.userErrors[0].message;
        }
        resolve(result);
      });

      res.on("error", function (error) {
        console.error(error);
        result.success = false;
        reject(result);
      });
    });

    var predat = JSON.stringify(payload)
    var postData = predat;

    req.write(postData);

    req.end();
  });
}
</script>
