// @ts-check

const configqa = {
  endpoint: "https://idealofswedencosmo.documents.azure.com:443/",
  key: "qIqjSfyThC3uwXk42OIu0Tvg5jGvwp0Waieci0dV7lu0oGiFpmTlkL1RuA3q1jZ1vaJEKX0E2KjZNXZnktfenA==",
  databaseId: "errors",
  containerId: "errorQa",
  partitionKey: { kind: "Hash", paths: ["/id"] }
};

module.exports = configqa;
