import Vue from "vue";
import App from "./App.vue";
import router from "./router/index.js";
import vuetify from './plugins/vuetify';
import JsonEditor from 'vue-json-edit'
// main.js file
const $cookies = require('vue-cookies')
Vue.use($cookies)
Vue.use(JsonEditor)

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount("#app");
