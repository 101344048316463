/* eslint-disable */

import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Qa from "../views/qa.vue";
import VueCookies from 'vue-cookies';
Vue.use(require('vue-cookies'))
Vue.use(VueCookies);
Vue.use(VueRouter);
const testing = false;
const cert = "MIIDdDCCAlygAwIBAgIGAYUP7675MA0GCSqGSIb3DQEBCwUAMHsxFDASBgNVBAoTC0dvb2dsZSBJ"


const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/qa",
    name: "Qa",
    component: Qa
  }
];

const router = new VueRouter({
  routes
});
if (!testing) {
  router.beforeEach(async (to, from, next) => {
    console.log(to);
    if (to.path == '/validate') {
      let obj = await Buffer.from(to.query.sl, "base64").toString("utf8")
      console.log(obj);
      if (obj.includes(cert)) { // Checking if the content is valid
        let pre = Buffer.from(cert, "utf8").toString("base64")
        Vue.$cookies.set('aW50cmFuZXRscw',pre,259200); // Setting a cookie with the saml response as content
        next('/') // Sending user to Homepage
      } else { // the user is trying to validate with an invalid respone, sending them to google
        window.location = "https://www.google.com/";
      }
    } else {
      if (Vue.$cookies.isKey("aW50cmFuZXRscw")) { // Checking if saml cookie exists
        let obj = await Buffer.from(Vue.$cookies.get('aW50cmFuZXRscw'), "base64").toString("utf8")
        if (cert == obj) { // Checking if the content is valid
          next() // Sending user to whereever they were trying to access
        } else { // User has a saml cookie but the content is invalid, sending them to login flow
          window.location = "https://europe-west1-ideal-data-warehouse.cloudfunctions.net/IIP_redirect?route=spinitsso-redirect";
        }
      } else { // Saml cookie does not exist, sending to login flow
        window.location = "https://europe-west1-ideal-data-warehouse.cloudfunctions.net/IIP_redirect?route=spinitsso-redirect";
      }
    }
  })
}


export default router;
