<template>
  <v-app>
    <v-app-bar app :color="env_color" dark>
      <div class="d-flex align-center">
        <v-img width="15em" src="./assets/IDEAL.png"></v-img>
    </div>
      <p style="padding-top:15px; padding-left:10px; font-size:20px">| {{ env_title }}</p>
      <v-spacer></v-spacer>
      <v-switch
      style="padding-top:20px"
      v-model="env"
      inset
      color="white"
      v-on:change="change"
      label="Environment"
      ></v-switch>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: "App",

  data: () => ({
    env: true,
    env_color: "",
    env_title: ""
  }),
  created () {
    if (this.$route.name == 'Home') {
      this.env_color = "black"
      this.env_title = "PROD"
      this.env = true
    }
    if (this.$route.name == 'Qa') {
      this.env_color = "orange"
      this.env_title = "QA"
      this.env = false
    }
    },
    updated: function () {
  this.$nextTick(function () {
    if (this.$route.name == 'Home') {
      this.env_color = "black"
      this.env_title = "PROD"
      this.env = true
    }
    if (this.$route.name == 'Qa') {
      this.env_color = "orange"
      this.env_title = "QA"
      this.env = false
    }
  })
},
  methods: {
    change () {
      if (this.env) {
        this.$router.push({ name: 'Home' })
        this.env_color = "black"
        this.env_title = "PROD"
      } else {
        this.$router.push({ name: 'Qa' })
        this.env_color = "orange"
        this.env_title = "QA"
      }
    }
  }
};
</script>
